import { useRef } from "react";
import "./QRInput.css";

export default function QRInput({ url, onBlur }) {
  const ref = useRef();

  function handleChange() {
    console.log(ref.current.value);
    onBlur(ref.current.value);
  }

  return (
    <textarea
      ref={ref}
      value={url}
      rows={3}
      className="QRContent"
      onChange={handleChange}
    />
  );
}
