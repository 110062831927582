import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import QRCode from "./pages/QRCode/QRCode";
import Cricut from "./pages/cricut";
import CalendarIndex from "./pages/calendar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>Hello world!</div>,
  },
  {
    path: "/qr",
    element: <QRCode />,
  },
  {
    path: "/cricut",
    element: <Cricut />,
  },
  {
    path: "/calendar",
    element: <CalendarIndex />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
