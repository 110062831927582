import { useState } from "react";
import QRGenerator from "./component/QRGenerator";
import QRReader from "./component/QRReader";
import "./QRCode.css";
import QRInput from "./component/QRInput";

function QRCode() {
  const [url, setUrl] = useState();

  const handleUrlChange = (url) => {
    setUrl(url);
  };

  return (
    <div className="QRCode">
      <div>
        <QRReader onSuccess={handleUrlChange} />
        <QRInput url={url} onBlur={handleUrlChange} />
      </div>
      <QRGenerator url={url} onUrlChange={handleUrlChange} />
    </div>
  );
}

export default QRCode;
