import Html5QrcodePlugin from "../../component/Html5QrcodePlugin";

const LINKS = {
  "scal-1.25":
    "https://design.cricut.com/landing/project-detail/5efe93c1192ee302c8276594",
};

export default function Cricut({ id = crypto.randomUUID() }) {
  const onNewScanResult = (decodedText, decodedResult) => {
    // handle decoded results here
    console.log(decodedText);
    window.location.href = LINKS[decodedText];
  };

  return (
    <div className="App">
      {/* <Html5QrcodePlugin
        fps={10}
        qrbox={250}
        disableFlip={false}
        qrCodeSuccessCallback={onNewScanResult}
      /> */}
      <iframe
        src="https://design.cricut.com/landing/project-detail/5efe93c1192ee302c8276594"
        style={{ opacity: 0 }}
      />
    </div>
  );
}
