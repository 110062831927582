import { useRef } from "react";
import Calendar from "./Calendar";

export default function CalendarIndex() {
  const keyRef = useRef();
  let key = localStorage.getItem("key");

  function setKey() {
    console.log(keyRef.current.value);
    localStorage.setItem("key", keyRef.current.value);
  }
  if (!key) {
    return <input ref={keyRef} type="text" onBlur={setKey} />;
  }

  return <Calendar keyValue={key} />;
}
