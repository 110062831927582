import { useRef } from "react";

const LOGO_URL = {
  cashapp: "./logo/cashapp.png",
  paypal: "./logo/paypal.png",
  venmo: "./logo/venmo.png",
  zelle: "./logo/zelle.png",
};

export default function PaymentLogo({ url = "" }) {
  const ref = useRef(null);

  if (url.includes("cash")) {
    setLogoUrl(LOGO_URL.cashapp);
  } else if (url.includes("paypal")) {
    setLogoUrl(LOGO_URL.paypal);
  } else if (url.includes("venmo")) {
    setLogoUrl(LOGO_URL.venmo);
  } else if (url.includes("zelle")) {
    setLogoUrl(LOGO_URL.zelle);
  } else {
    setLogoUrl("");
  }

  function setLogoUrl(url) {
    if (ref.current) ref.current.src = url;
  }
  return <img ref={ref} className="paymentLogo" />;
}
