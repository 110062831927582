import FullCalendar from "@fullcalendar/react";
import iCalendarPlugin from "@fullcalendar/icalendar";
import dayGridPlugin from "@fullcalendar/daygrid";

const events = [
  // {
  //   url: "/api/calendar/events/google",
  //   format: "ics",
  // },
  {
    url: "/api/calendar/events/school",
    format: "ics",
  },
];

export default function Calendar({ keyValue }) {
  // fetch(
  //   "https://calendar.google.com/calendar/ical/nknt2004%40gmail.com/public/basic.ics"
  // ).then((response) => {
  //   const events = ICAL.parse(response.body);
  //   console.log(events);
  // });

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, iCalendarPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        eventSources={events}
        // eventContent={renderEventContent}
      />
    </div>
  );
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}
